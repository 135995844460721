<template>
  <div class="areaPickerPage">
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="list" @confirm="onConfirm" @cancel="onCancel" :confirm-button-text="confirmTxt" :cancel-button-text="cancelTxt" />
    </van-popup>
  </div>
</template>
  
<script>
  export default {
    name: "tab",
    data() {
      return {
        showPicker: false,
        params: {},
        list: [],
        confirmTxt:'确定',
        cancelTxt:'取消'
      };
    },
    methods: {
      parentMsg(params) {
        this.list = []
        this.showPicker = true;
        this.params = params
        if(params && params.ins && (params.ins === 1)){
          this.confirmTxt = 'confirm'
          this.cancelTxt = 'cancel'
        } else {
          this.confirmTxt = '确定'
          this.cancelTxt = '取消'
        }
        this.list = params.struct.data
      },
      onCancel() {
        this.showPicker = false;
      },
      onConfirm(value) {
        this.params.struct.value = value
        this.$emit('click', this.params);
        this.showPicker = false;
      }
    }
  };
</script>
<style lang='scss' scoped>
   .areaPickerPage{
    /deep/.van-popup .van-picker .van-picker__columns{
      .van-picker-column {
        font-size: 12px;
      }
    }
  }
</style>