import http from "@/utils/request.js";

/**
 * @methods 获取下拉选项数据
 */
export const getNewInterHistoryOption = (params) => {
	return http({
		url: "/product/getUvlList",
		method: "get",
		params,
	});
};

/**
 * @methods 获取表格数据
 */
export const getNewInterHistoryTable = (params) => {
	return http({
		url: "/product/getUvlPriceHis",
		method: "post",
		data:params
	});
};
