<template>
  <div>
    <div class="mobile-history">
      <header>
        <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
      </header>
      <crumbs :item="title"></crumbs>
      <div class="main-content">
        <div class="wrap">
          <p>查询公布利率</p>
          <div class="tab">
            <div class="account">
              <van-cell-group>
                <van-field
                  v-model="countTypeFlag.value.text"
                  label="账户类型"
                  right-icon="arrow"
                  placeholder="选择账户类型"
                  readonly
                  @click="showSelectPicker(countTypeFlag)"
                />
              </van-cell-group>
            </div>
            <div class="content">
              <van-list
                v-model="loading"
                finished-text=""
                offset="0"
                :finished="loading"
                :immediate-check="false"
              >
                <div
                  class="item"
                  v-for="(item, index) in tableData"
                  :key="index"
                >
                  <div>序号：{{ item.id }}</div>
                  <div class="item-row">
                    <div>利率始日：{{ item.startday }}</div>
                    <div>利率止日：{{ item.endday }}</div>
                  </div>
                  <div class="item-row">
                    <div>保证年利率：{{ item.guaranterate }}%</div>
                    <div>浮动年利率：{{ item.floatrate }}%</div>
                  </div>
                  <div class="item-row">
                    <div>月结算日利率：{{ item.dayannualrate }}%</div>
                    <div>月结算年利率：{{ item.setannualrate }}%</div>
                  </div>
                </div>
              </van-list>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="qrcode">
          <div class="item">
            <img src="~@/static/images/public.jpg" alt="" />
            <span>公众号：安联人寿</span>
          </div>
          <div class="item">
            <img src="~@/static/images/service.jpg" alt="" />
            <span>服务号-安联在线</span>
          </div>
        </div>
        <div class="info">
          <p>© 安联人寿保险有限公司2012.All Rights Reserved.</p>
          <p class="beian">沪ICP备07011309号-1</p>
        </div>
      </div>
    </div>
    <!-- 选择 -->
    <selectPicker ref="selectPicker" @click="updataSelectPicker"></selectPicker>
  </div>
</template>

<script>
import selectPicker from "@/components/selectPicker.vue";
import crumbs from "@/components/crumbs.vue";
import {
  getNewInterHistoryTable,
  getNewInterHistoryOption,
} from "@/api/newsnew/interestrateHistory";
export default {
  name: "interestrateHistoryMobile",
  props: {},
  data() {
    return {
      title: [
        {
          title: "新闻公告",
          link: "",
        },
        {
          title: "万能险利率公告",
          link: "/announcement",
        },
        {
          title: "查询公布利率",
          link: "/interestrateHistory",
        },
      ],
      countTypeFlag: {
        //国籍注册地址
        value: {
          value: "",
          text: "",
        },
        data: [],
      },
      countType: "", //下拉框选中值
      tableData: [], //table数据
      loading: false, //加载
    };
  },
  components: {
    crumbs,
    selectPicker,
  },
  methods: {
    //下拉选项触发函数
    async onChangeCountType() {
      console.log(this.countTypeFlag.value.text);
      await this.getNewInterHistoryTable(this.countTypeFlag.value.value);
    },

    //获取下拉选项数据
    async getNewInterHistoryOption() {
      const [timeStamp, sign] = this.$getSign();
      const {
        data: { rows },
      } = await getNewInterHistoryOption({ timeStamp, sign });
      this.countTypeFlag.data = rows.map(({ FID, ACCOUNT_NAME }) => ({
        value: FID,
        text: ACCOUNT_NAME,
      }));
      if (this.countTypeFlag.value.text == "") {
        this.countTypeFlag.data.forEach((item) => {
            if(this.countType==item.value)
            return this.countTypeFlag.value.text=item.text;
        });
      }
    },

    //获取历史公布利率数据
    async getNewInterHistoryTable(fid) {
      this.loading = true;
      const [timeStamp, sign] = this.$getSign();
      const {
        data: { rows },
      } = await getNewInterHistoryTable({ timeStamp, sign, fid });
      this.tableData = rows.map((item, index) => {
        const {
          START_DATE,
          END_DATE,
          GRNT_RATE,
          FLOAT_RATE,
          DAY_RATE,
          INV_RATE,
        } = item;
        return {
          id: index,
          startday: START_DATE ? START_DATE.substring(0, 10) : "",
          endday: END_DATE ? END_DATE.substring(0, 10) : "",
          guaranterate: GRNT_RATE ? GRNT_RATE : "0",
          floatrate: FLOAT_RATE ? FLOAT_RATE : "0",
          dayannualrate: DAY_RATE ? DAY_RATE.toFixed(3) : "0",
          setannualrate: INV_RATE ? INV_RATE : "0",
        };
      });
      this.loading = false;
    },
    showSelectPicker(params) {
      this.$refs.selectPicker.parentMsg({
        struct: { ...params, type: "selectPickerFlag" },
      });
    },
    updataSelectPicker(params) {
      this.countTypeFlag.value = params.struct.value;
      this.onChangeCountType();
    },
  },
  async created() {
    this.countType = localStorage.getItem("interestrateHistoryCovercode");
    this.getNewInterHistoryTable(this.countType);
    await this.getNewInterHistoryOption(); //获取选项数据
  },
};
</script>
<style lang='scss' scoped>
@function px2rem($px, $screen: 1920) {
  @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
}

@function px2vw($px) {
  @return calc(100vw * #{$px} / 1920);
}
 ::-webkit-scrollbar {
  width: 3px !important;
}

.mobile-history {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  padding-bottom: px2rem(30, 375);

  header {
    width: 100%;
    height: px2rem(50, 375);
    display: flex;
    align-items: center;
    padding-left: px2rem(15, 375);
    img {
      width: px2rem(75, 375);
      height: px2rem(30, 375);
    }
  }
  .main-content {
    width: 100%;
    padding: px2rem(10, 375) px2rem(10, 375);
    padding-bottom: px2rem(120, 375);
    height: 100%;
   overflow: hidden;
    .wrap {
      width: 100%;
      padding: 0;

      > p {
        text-align: center;
        font-size: px2rem(16, 375);
        margin: px2rem(10, 375) 0;
        color: #003781;
      }

      > .tab {
        //width: 60%;
        /* width: 129rem; */
        background: #fff;
        margin: auto;
        /* padding: 0  px2rem(10, 375) px2rem(10, 375); */
        // height: 52rem;
        /* margin-bottom: 6rem; */

        //账户
        .account {
          background: #edeff5;
          padding: px2rem(10, 375) px2rem(10, 375) px2rem(10, 375)
            px2rem(10, 375);
          font-size: px2rem(16, 375);
          /deep/ .van-field__label {
            width: unset;
          }
          /deep/ .van-field__control {
            font-size: calc(100vw / (375 / 10) * 12 / 10);
          }
          /deep/ .van-cell {
            padding: px2rem(6, 375) px2rem(12, 375);
            font-size: px2rem(12, 375);
          }
        }
        .content {
          height: px2rem(400, 375);
          overflow-x: hidden;
          margin-top: px2rem(10, 375);
          .item {
            background: #edeff5;
            padding: px2rem(10, 375);
            margin-bottom: px2rem(10, 375);
            border-radius: px2rem(10, 375);
            font-size: px2rem(14, 375);
            .item-row {
              display: flex;
              justify-content: space-between;
              div {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    background-color: #fff;
    padding: px2rem(10, 375) 0;
    position: absolute;
    bottom: 0;
    z-index: 9999;
    .qrcode {
      display: flex;
      justify-content: space-evenly;
      margin: px2rem(30, 375) 0;
      display: none;

      .item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        img {
          width: px2rem(100, 375);
          height: px2rem(100, 375);
        }
        span {
          font-size: px2rem(13, 375);
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }
    }

    .info {
      p {
        font-size: px2rem(13, 375);
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;

        &.beian {
          color: RGBA(38, 77, 139, 1);
        }
      }
    }
  }
}
</style>